import { API, Auth } from "aws-amplify";

const getUsersCourts = async (email: string): Promise<string[]> => {
  const apiName = "csvApi";
  const path = `/private/admin/getUsersCourts`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: {
      email,
    },
  };

  const result: string[] = await API.post(apiName, path, myInit);
  return result;
};

export default getUsersCourts;
