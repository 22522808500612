import { Button, PageHeader, Space, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { courts } from "csv-package";

import { RootState } from "../state/store";

const mapState = (state: RootState) => ({
  courtIDs: state.user.courtIDs,
  groups: state.user.data?.signInUserSession.idToken.payload["cognito:groups"],
});

type Props = ReturnType<typeof mapState> & RouteComponentProps;

const Courts: React.FunctionComponent<Props> = (props) => {
  const filterLogic = (court: { court_id: string }) => {
    // if superadmin let everything through
    if (props.groups?.includes("Superadmins")) {
      return true;
    }
    if (props.courtIDs === undefined) {
      return false;
    }

    if (court.hasOwnProperty("court_id")) {
      // must be regular Admin
      return props.courtIDs.includes(court?.court_id);
    }
    return false;
  };

  return (
    <>
      <PageHeader onBack={() => props.history.push("/")} title="Courts" />
      <Table
        pagination={{ defaultPageSize: 100 }}
        rowKey="court_id"
        loading={props.courtIDs === undefined}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
              <Space size="middle">
                <Button
                  onClick={() =>
                    props.history.push(`/court/${record.court_id}`)
                  }
                >
                  View
                </Button>
              </Space>
            ),
          },
        ]}
        dataSource={courts
          .filter(filterLogic)
          .sort((a, b) => (a.name < b.name ? -1 : 1))}
      ></Table>
    </>
  );
};

export default connect(mapState)(Courts);
