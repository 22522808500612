import { createModel } from "@rematch/core";
import { RootModel } from "./models";

type UserPoolGroups = "Superadmins" | "Admins" | "Registrars";

export type AuthData = {
  username: string;
  pool: object;
  Session: null;
  client: object;
  signInUserSession: {
    idToken: {
      jwtToken: string;
      payload: {
        at_hash: string;
        sub: string;
        ["cognito:groups"]: UserPoolGroups[];
      };
    };
  };
  attributes: {
    email: string;
  };
};

interface UserState {
  data: AuthData | null | undefined;
  courtIDs: string[] | undefined;
}

export const user = createModel<RootModel>()({
  state: {
    data: undefined,
    courtIDs: undefined,
  } as UserState, // typed complex state
  reducers: {
    setUser(state, payload: AuthData | null) {
      return {
        ...state,
        data: payload,
      };
    },
    setCourtIDs(state, payload: string[]) {
      return {
        ...state,
        courtIDs: payload,
      };
    },
  },
});
