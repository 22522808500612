import { API, Auth } from "aws-amplify";
import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Input,
  PageHeader,
  Popconfirm,
  Spin,
  Table,
  Tag,
} from "antd";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import * as Yup from "yup";
import _ from "lodash";

import { GroupType, UserType } from "../types";
import getGroupUsers from "../helpers/getGroupUsers";
import deleteUser from "../helpers/deleteUser";
import removeUserFromGroup from "../helpers/removeUserFromGroup";
import addUserToGroup from "../helpers/addUserToGroup";
import { RootState } from "../state/store";
import UpdateUserPasswordModal from "../components/UpdateUserPasswordModal";

type FormikValues = {
  given_name: string;
  family_name: string;
  email: string;
  groups: string[];
};

const mapState = (state: RootState) => ({
  user: state.user.data,
});

type StateProps = ReturnType<typeof mapState>;
type Props = StateProps;

const Users: React.FC<Props> = (props) => {
  const { push } = useHistory();

  const [users, setUsers] = React.useState<UserType[]>([]);
  const [allRegistrars, setAllRegistrars] = React.useState<UserType[]>([]);
  const [allAdmins, setAllAdmins] = React.useState<UserType[]>([]);
  const [allSuperadmins, setAllSuperadmins] = React.useState<UserType[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<null | UserType>(null);

  React.useEffect(() => {
    const fetchAllSuperadmins = async () => {
      const result = await getGroupUsers("SUPERADMINS");
      setAllSuperadmins(result);
    };
    fetchAllSuperadmins();

    const fetchAllAdmins = async () => {
      const result = await getGroupUsers("ADMINS");
      setAllAdmins(result);
    };
    fetchAllAdmins();

    const fetchAllRegistrars = async () => {
      const result = await getGroupUsers("REGISTRARS");
      setAllRegistrars(result);
    };
    fetchAllRegistrars();

    const fetchUser = async () => {
      const apiName = "csvApi";
      const path = `/private/admin/getAllUsers`;
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      };
      const v = await API.post(apiName, path, myInit);
      setUsers(v);
    };

    fetchUser();
  }, []);

  const initialValues: FormikValues = {
    given_name: "",
    family_name: "",
    email: "",
    groups: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      given_name: Yup.string().required("Given name is required"),
      family_name: Yup.string().required("Family name is required"),
      email: Yup.string().required("Email is required").email(),
    }),
    onSubmit: async (values, formikBag) => {
      const apiName = "csvApi";
      const path = `/private/admin/createUser`;
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: values,
      };

      const v = await API.post(apiName, path, myInit);
      formikBag.setSubmitting(false);
      formikBag.resetForm();

      if (!v) {
        alert("error adding user");
      }
      setUsers((v) => [...v, values]);
      if (values.groups.includes("Admins")) {
        setAllAdmins((v) => [...v, values]);
      }
      if (values.groups.includes("Registrars")) {
        setAllRegistrars((v) => [...v, values]);
      }
    },
  });

  const handleDeleteUser = async (email: string): Promise<void> => {
    // get a store user
    const userToDelete = users.find((user) => user.email === email);

    // removes visually
    setUsers((users) => users.filter((user) => user.email !== email));

    // do api call
    const result = await deleteUser(email);

    // put user back visually
    if (!result && userToDelete) {
      alert("User delete failed, please try again.");
      setUsers((users) => [...users, userToDelete]);
    }
  };

  const handleRemoveUserFromGroup = async (
    email: string,
    group: GroupType
  ): Promise<void> => {
    if (group === "ADMINS") {
      setAllAdmins((admins) => admins.filter((admin) => admin.email !== email));
    } else {
      setAllRegistrars((registrars) =>
        registrars.filter((registrar) => registrar.email !== email)
      );
    }
    try {
      await removeUserFromGroup({ email, group });
    } catch (error) {
      // restore users view if failure
      alert("Error while removing user from group, please try again.");
      const userToAddBackToGroup = users.find((user) => user.email === email);
      if (group === "ADMINS" && userToAddBackToGroup) {
        setAllAdmins((admins) => [...admins, userToAddBackToGroup]);
      } else if (userToAddBackToGroup) {
        setAllRegistrars((registrars) => [...registrars, userToAddBackToGroup]);
      }
    }
  };

  const handleAddUserToGroup = async (
    email: string,
    group: GroupType
  ): Promise<void> => {
    const userToAddToGroup = users.find((user) => user.email === email);
    if (group === "ADMINS" && userToAddToGroup) {
      setAllAdmins((admins) => [...admins, userToAddToGroup]);
    } else if (userToAddToGroup) {
      setAllRegistrars((registrars) => [...registrars, userToAddToGroup]);
    }
    try {
      await addUserToGroup({ email, group });
    } catch (error) {
      alert("Error while adding user to group, please try again");
      if (group === "ADMINS" && userToAddToGroup) {
        setAllAdmins((admins) =>
          admins.filter((admin) => admin.email === email)
        );
      } else {
        setAllRegistrars((registrars) =>
          registrars.filter((registrar) => registrar.email === email)
        );
      }
    }
  };

  return (
    <>
      <div>
        <PageHeader onBack={() => push("/")} title="Users" />
        <div>
          {formik.isSubmitting ? (
            <Spin />
          ) : (
            <form>
              <p>
                <label htmlFor="given_name">Given Name</label>
                <br />
                <Input
                  id="given_name"
                  name="given_name"
                  placeholder="Given Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </p>
              <p>
                <label htmlFor="family_name">Family Name</label>
                <br />
                <Input
                  id="family_name"
                  name="family_name"
                  placeholder="Family Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </p>
              <p>
                <label htmlFor="email">Email</label>
                <br />
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="user.user@mcv.vic.gov.au"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </p>

              <p>
                <Checkbox
                  type="checkbox"
                  id="groups-admins"
                  name="groups"
                  value="Admins"
                  onChange={formik.handleChange}
                />{" "}
                <label htmlFor="groups-admins">Court Admin</label>
                <br />
                <Checkbox
                  type="checkbox"
                  id="groups-registrars"
                  name="groups"
                  value="Registrars"
                  onChange={formik.handleChange}
                />{" "}
                <label htmlFor="groups-registrars">Court Registrars</label>
                <br />
              </p>

              {Object.keys(formik.errors)
                .filter((key) => formik.touched.hasOwnProperty(key))
                .map((key) => (
                  <Alert
                    type="error"
                    message={_.get(formik, `errors.${key}`, "")}
                    key={key}
                  />
                ))}
              <br />

              <Button
                type="primary"
                onClick={() => formik.submitForm()}
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Adding..." : "Add"}
              </Button>
            </form>
          )}
        </div>

        <Divider />

        <Table
          rowKey="email"
          loading={users.length === 0}
          pagination={false}
          columns={[
            {
              title: "Given name",
              dataIndex: "given_name",
              key: "given_name",
            },
            {
              title: "Family name",
              dataIndex: "family_name",
              key: "family_name",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Status",
              key: "UserStatus",
              render: (_, record) => record.data?.UserStatus || "",
            },
            {
              title: "Roles",
              key: "groups",
              render: (_, record) => {
                const isRegistrar = allRegistrars.find(
                  (registrarUser) => registrarUser.email === record.email
                );
                const isAdmin = allAdmins.find(
                  (adminUser) => adminUser.email === record.email
                );
                const isSuperadmin = allSuperadmins.find(
                  (sa) => sa.email === record.email
                );
                return (
                  <>
                    {isRegistrar && <Tag>Registrar</Tag>}
                    {isAdmin && <Tag>Admin</Tag>}
                    {isSuperadmin && <Tag>Superadmin</Tag>}
                  </>
                );
              },
            },
            {
              title: "Actions",
              key: "actions",
              render: (_, record) => {
                const isRegistrar = allRegistrars.find(
                  (registrarUser) => registrarUser.email === record.email
                );
                const isAdmin = allAdmins.find(
                  (adminUser) => adminUser.email === record.email
                );
                return (
                  <>
                    {!isRegistrar ? (
                      <>
                        <Button
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() =>
                            handleAddUserToGroup(record.email, "REGISTRARS")
                          }
                        >
                          Registrar
                        </Button>{" "}
                      </>
                    ) : (
                      <Popconfirm
                        title="Are you sure you want to remove this user from Registrars"
                        onConfirm={() =>
                          handleRemoveUserFromGroup(record.email, "REGISTRARS")
                        }
                      >
                        <Button danger size="small" icon={<CloseOutlined />}>
                          Registrar
                        </Button>{" "}
                      </Popconfirm>
                    )}
                    {!isAdmin ? (
                      <>
                        <Button
                          size="small"
                          icon={<PlusOutlined />}
                          onClick={() =>
                            handleAddUserToGroup(record.email, "ADMINS")
                          }
                        >
                          Admin
                        </Button>{" "}
                      </>
                    ) : (
                      <Popconfirm
                        title="Are you sure you want to remove this user from Admins"
                        onConfirm={() =>
                          handleRemoveUserFromGroup(record.email, "ADMINS")
                        }
                      >
                        <Button danger size="small" icon={<CloseOutlined />}>
                          Admin
                        </Button>{" "}
                      </Popconfirm>
                    )}
                    {allSuperadmins.find((sa) => sa.email === record.email) ===
                      undefined && (
                      <Popconfirm
                        title="Are you sure you want to delete this user?"
                        onConfirm={() => handleDeleteUser(record.email)}
                      >
                        <Button
                          size="small"
                          type="primary"
                          icon={<DeleteOutlined />}
                          danger
                        >
                          Delete
                        </Button>{" "}
                      </Popconfirm>
                    )}
                    <Button
                      onClick={() => setSelectedUser(record)}
                      size="small"
                      type="primary"
                    >
                      Reset password
                    </Button>
                  </>
                );
              },
            },
          ]}
          dataSource={users.sort((a, b) => {
            if (a.email < b.email) {
              return -1;
            }
            if (a.email > b.email) {
              return 1;
            }
            return 0;
          })}
        />
      </div>
      {selectedUser !== null && (
        <UpdateUserPasswordModal
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
        />
      )}
    </>
  );
};

export default connect(mapState)(Users);
