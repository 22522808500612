import { GroupType } from "../types";

const calculateGroup = (value: string): GroupType => {
  const upperCaseString = value.toUpperCase();
  if (upperCaseString === "REGISTRARS") {
    return "REGISTRARS";
  }
  if (upperCaseString === "SUPERADMINS") {
    return "SUPERADMINS";
  }

  return "ADMINS";
};

export default calculateGroup;
