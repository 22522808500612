import { API, Auth } from "aws-amplify";
import React from "react";
import { Button, Input, PageHeader, Space, Table } from "antd";
import { useHistory } from "react-router";
import Modal from "antd/lib/modal/Modal";
import styled from "styled-components";

type ApplicantType = {
  given_name: string;
  email: string;
  username: string;
};

const Applicants = () => {
  const { push } = useHistory();

  const [errorMessage, setErrorMessage] = React.useState<null | string>(null);
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [applicantList, setApplicantList] = React.useState<
    null | ApplicantType[]
  >();
  const [searching, setSearching] = React.useState<boolean>(false);

  const [
    selectedApplicant,
    setSelectedApplicant,
  ] = React.useState<null | ApplicantType>(null);

  const findUser = async () => {
    setSearching(true);
    const apiName = "csvApi";
    const path = `/private/admin/getApplicants`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: {
        term: searchTerm,
      },
    };

    const result = await API.post(apiName, path, myInit);

    const list: ApplicantType[] = result.map((r: any) => ({
      given_name: r.given_name,
      email: r.email,
      username: r.data.Username,
    }));

    setApplicantList(list);
    setSearching(false);
  };

  const handleOk = async () => {
    if (selectedApplicant === null) {
      return;
    }
    setProcessing(true);
    const apiName = "csvApi";
    const path = `/private/admin/updateApplicantPassword`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: {
        password: newPassword,
        username: selectedApplicant.username,
      },
    };

    try {
      await API.post(apiName, path, myInit);
      setSelectedApplicant(null);
      setNewPassword("");
      setProcessing(false);
      setErrorMessage(null);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      setProcessing(false);
    }
  };

  const handleModalCancel = () => {
    setSelectedApplicant(null);
    setNewPassword("");
    setProcessing(false);
    setErrorMessage(null);
  };

  return (
    <>
      <PageHeader onBack={() => push("/")} title="Applicants" />
      <SearchBar>
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          placeholder="Applicant name"
        />
        <Button onClick={() => findUser()} type="primary" disabled={searching}>
          {searching ? "Searching" : "Go"}
        </Button>
      </SearchBar>

      {applicantList !== null && (
        <Table
          loading={searching}
          rowKey="email"
          dataSource={applicantList}
          columns={[
            {
              title: "Given name",
              dataIndex: "given_name",
              key: "given_name",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle">
                  <Button onClick={() => setSelectedApplicant(record)}>
                    Reset password
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      )}
      {selectedApplicant !== null && (
        <Modal
          title={`Reset password for ${selectedApplicant.given_name} (${selectedApplicant.email})`}
          visible={selectedApplicant !== null}
          onCancel={handleModalCancel}
          confirmLoading={processing}
          onOk={handleOk}
        >
          <label htmlFor="new_password">New password</label>
          <Input
            name="new_password"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
          />
          {errorMessage !== null && (
            <>
              <div>&nbsp;</div>
              <p style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</p>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default Applicants;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  input {
    width: 20em;
    margin-right: 0.5em;
    margin-bottom: 1.2em;
  }
`;
