import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Amplify, { Auth, Hub, API } from "aws-amplify";
import { Layout } from "antd";
import "antd/dist/antd.css";

import { AuthData } from "./state/user";
import { Dispatch } from "./state/store";

import GlobalStyle from "./styles/GlobalStyle";
import queryStringToJSON from "./helpers/queryStringToJSON";
import getUsersCourts from "./helpers/getUsersCourts";

import ProtectedRoute from "./components/ProtectedRoute";
import Header from "./components/Header";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Court from "./pages/Court";
import Users from "./pages/Users";
import Courts from "./pages/Courts";
import Forbidden from "./pages/Forbidden";
import ApplicationsPage from "./pages/Applications";
import Applicants from "./pages/Applicants";
import Courtlink from "./pages/Courtlink";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_STAFF_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    region: process.env.REACT_APP_AWS_REGION,
    oauth: {
      domain: `${process.env.REACT_APP_STAFF_USER_POOL_DOMAIN}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com`,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${process.env.REACT_APP_ADMIN_USER_POOL_CLIENT_CALLBACK_URL}`,
      redirectSignOut: `${process.env.REACT_APP_ADMIN_USER_POOL_CLIENT_CALLBACK_URL}`,
      responseType: "code",
    },
  },
});
API.configure({
  endpoints: [
    {
      name: "csvApi",
      endpoint: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_STAGE}`,
      region: process.env.REACT_APP_AWS_REGION,
    },
  ],
});

const mapDispatch = (dispatch: Dispatch) => ({
  setUser: dispatch.user.setUser,
  setCourtIDs: dispatch.user.setCourtIDs,
});

type Props = ReturnType<typeof mapDispatch>;

const App: React.FunctionComponent<Props> = (props) => {
  // Hosted UI Cognito login
  React.useEffect(() => {
    const setUserCourts = async (email: string): Promise<void> => {
      const courtIDs = await getUsersCourts(email);
      props.setCourtIDs(courtIDs);
    };

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "cognitoHostedUI":
          console.log("Hosted UI");
          break;
        case "signIn":
          props.setUser(data);
          if (data.attributes) {
            setUserCourts(data.attributes.email);
          }
          break;
        case "signOut":
          props.setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          props.setUser(null);
          console.log("Sign in failure", data);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((data: AuthData) => {
        props.setUser(data);
        setUserCourts(data.attributes.email);
      })
      .catch(() => props.setUser(null));
  }, [props]);

  return (
    <Layout>
      <GlobalStyle />
      <Router>
        <Route
          path="/"
          render={(routeProps) => {
            const params = queryStringToJSON(routeProps.location.search);

            var redirect = _.get(params, "redirect", null);

            if (!redirect) {
              return null;
            }

            return (
              <Redirect to={`${redirect}?code=${_.get(params, "code", "")}`} />
            );
          }}
        />
        <Header />
        <Switch>
          <Layout.Content
            style={{ padding: "0 50px", minHeight: "calc(100vh - 64px)" }}
          >
            <ProtectedRoute path="/" exact component={Dashboard} />
            <ProtectedRoute path="/court/:courtID" exact component={Court} />
            <ProtectedRoute path="/courts" exact component={Courts} />
            <ProtectedRoute path="/court" exact component={Courts} />
            <ProtectedRoute path="/users" exact component={Users} />
            <ProtectedRoute path="/applicants" exact component={Applicants} />
            <ProtectedRoute path="/courtlink" exact component={Courtlink} />
            <ProtectedRoute
              path="/applications"
              exact
              component={ApplicationsPage}
            />
            <Route path="/forbidden" exact component={Forbidden} />
            <Route path="/login" exact component={Login} />
            <Route render={() => <Redirect to="/" />} />
          </Layout.Content>
        </Switch>
      </Router>
    </Layout>
  );
};

export default connect(null, mapDispatch)(App);
