import { PageHeader, Table, Button, Space, Modal } from "antd";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { RootState } from "../state/store";

import getCourtlinkFiles from "../helpers/getCourtlinkFiles";
import getCourtlinkFile from "../helpers/getCourtlinkFile";

const mapState = (state: RootState) => ({
  groups: state.user.data?.signInUserSession.idToken.payload["cognito:groups"],
});

type Props = ReturnType<typeof mapState> & RouteComponentProps;

type ModalType = {
  visible: boolean;
  content: string;
  title: string;
};

const Courtlink: React.FunctionComponent<Props> = (props) => {
  const [files, setFiles] = React.useState<null | string[]>(null);
  const [modal, setModal] = React.useState<ModalType>({
    visible: false,
    content: "",
    title: "",
  });

  React.useEffect(() => {
    const fetch = async () => {
      const result = await getCourtlinkFiles();
      setFiles(result);
    };
    fetch();
  }, []);

  const getFileContent = async (key: string): Promise<void> => {
    if (modal.title === key) {
      setModal((s) => ({ ...s, visible: true }));
    } else {
      const result = await getCourtlinkFile(key);
      setModal({
        visible: true,
        content: result,
        title: key,
      });
    }
  };

  const closeModal = () => {
    setModal((s) => ({
      ...s,
      visible: false,
    }));
  };

  if (!props.groups?.includes("Superadmins")) {
    return null;
  }

  return (
    <>
      <PageHeader onBack={() => props.history.push("/")} title="Courtlink" />
      <Table
        pagination={{ defaultPageSize: 100 }}
        rowKey="court_id"
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
              <Space size="middle">
                <Button onClick={() => getFileContent(record.name)}>
                  View
                </Button>
              </Space>
            ),
          },
        ]}
        loading={files === null}
        dataSource={
          (files &&
            files
              .sort((a, b) => (b < a ? -1 : 1))
              .map((f) => ({
                name: f,
              }))) ||
          []
        }
      />
      <Modal
        title={modal.title}
        visible={modal.visible}
        onOk={closeModal}
        onCancel={closeModal}
        width={1280}
      >
        <pre style={{ fontSize: "10px" }}>{modal.content}</pre>
      </Modal>
    </>
  );
};

export default connect(mapState)(Courtlink);
