import { API, Auth } from "aws-amplify";
import { GroupType, UserType } from "../types";
import calculateGroup from "./calculateGroup";

const getGroupUsers = async (group: GroupType): Promise<UserType[]> => {
  const apiName = "csvApi";
  const path = `/private/admin/getGroupUsers`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: {
      group: calculateGroup(group),
    },
  };

  const result: UserType[] = await API.post(apiName, path, myInit);
  return result;
};

export default getGroupUsers;
