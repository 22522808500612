import { API, Auth } from "aws-amplify";
import { GroupType } from "../types";

interface AddUserToGroupParams {
  email: string;
  group: GroupType;
}

const addUserToGroup = async (values: AddUserToGroupParams): Promise<boolean> => {
  const apiName = "csvApi";
  const path = `/private/admin/addUserToGroup`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: {
      email: values.email,
      group: values.group,
    },
  };

  try {
    await API.post(apiName, path, myInit);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default addUserToGroup;
