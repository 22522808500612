import React from "react";
import { useFormik } from "formik";
import { RouteComponentProps } from "react-router-dom";
import {
  Button,
  Divider,
  PageHeader,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { courts } from "csv-package";

import { GroupType, UserType } from "../types";

import removeUserFromCourt from "../helpers/removeUserFromCourt";
import addUserToCourt from "../helpers/addUserToCourt";
import getCourtUsers from "../helpers/getCourtUsers";
import getGroupUsers from "../helpers/getGroupUsers";

type TParams = { courtID: string };

const Court: React.FunctionComponent<RouteComponentProps<TParams>> = (
  props
) => {
  const court = courts.find(
    (court) => court.court_id === props.match.params.courtID
  );

  const [courtRegistrars, setCourtRegistrars] = React.useState<UserType[]>([]);
  const [allRegistrars, setAllRegistrars] = React.useState<UserType[]>([]);
  const [courtAdmins, setCourtAdmins] = React.useState<UserType[]>([]);
  const [allAdmins, setAllAdmins] = React.useState<UserType[]>([]);
  const [loadingComplete, setLoadingComplete] = React.useState<boolean[]>([]);

  // Do fetching
  React.useEffect(() => {
    const fetcCourtUsers = async () => {
      const courtUsers = await getCourtUsers(props.match.params.courtID);
      setCourtRegistrars(
        courtUsers.filter((user) => user.group === "REGISTRARS")
      );
      setCourtAdmins(courtUsers.filter((user) => user.group === "ADMINS"));
      setLoadingComplete((l) => [...l, true]);
    };
    fetcCourtUsers();

    const fetchAllAdmins = async () => {
      const result = await getGroupUsers("ADMINS");
      setAllAdmins(result);
      setLoadingComplete((l) => [...l, true]);
    };
    fetchAllAdmins();

    const fetchAllRegistrars = async () => {
      const result = await getGroupUsers("REGISTRARS");
      setAllRegistrars(result);
      setLoadingComplete((l) => [...l, true]);
    };
    fetchAllRegistrars();
  }, [props.match.params.courtID]);

  // setup formik for registrars
  const registrarsFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values, formikBag) => {
      const selectedUser = allRegistrars.find(
        (user) => user.email === values.email
      );
      if (!selectedUser) {
        return null;
      }

      const addUserToCourtResult = await addUserToCourt({
        email: values.email,
        given_name: selectedUser.given_name,
        family_name: selectedUser.family_name,
        courtID: court?.court_id || "",
        group: "REGISTRARS",
      });

      // add user to state
      if (addUserToCourtResult) {
        setCourtRegistrars((v: UserType[]): UserType[] => [...v, selectedUser]);
      } else {
        alert("There was an error adding user to Court please try again.");
      }
      formikBag.setFieldValue("email", "");
      formikBag.resetForm();
    },
  });

  // setup formik for registrars
  const adminsFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values, formikBag) => {
      const selectedUser = allAdmins.find(
        (user) => user.email === values.email
      );
      if (!selectedUser) {
        return null;
      }

      const addUserToCourtResult = await addUserToCourt({
        email: values.email,
        given_name: selectedUser.given_name,
        family_name: selectedUser.family_name,
        courtID: court?.court_id || "",
        group: "ADMINS",
      });

      // add user to state
      if (addUserToCourtResult) {
        setCourtAdmins((v: UserType[]): UserType[] => [...v, selectedUser]);
      } else {
        alert("There was an error adding user to Court please try again.");
      }
      formikBag.setFieldValue("email", "");
      formikBag.resetForm();
    },
  });

  const handleRemoveUser = async (
    group: GroupType,
    email: string
  ): Promise<void> => {
    const courtID = court?.court_id;
    if (group === "REGISTRARS") {
      setCourtRegistrars((users) => users.filter((u) => u.email !== email));
    } else if (group === "ADMINS") {
      setCourtAdmins((users) => users.filter((u) => u.email !== email));
    }

    if (!courtID) {
      alert("Remove failed please try again.");
      return;
    }
    const removeResult = await removeUserFromCourt({
      email,
      group,
      courtID,
    });

    if (!removeResult) {
      alert("Error removing user from court");
      if (group === "REGISTRARS") {
        const userToRestore =
          allRegistrars.find((u) => u.email === email) || allRegistrars[0];
        setCourtRegistrars((users) => [...users, userToRestore]);
      } else if (group === "ADMINS") {
        const userToRestore =
          allAdmins.find((u) => u.email === email) || allAdmins[0];
        setCourtAdmins((users) => [...users, userToRestore]);
      }
    }
  };

  if (!court || loadingComplete.length < 3) {
    return (
      <div
        style={{
          minHeight: "calc(100vh - 64px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        onBack={() => props.history.push("/court")}
        title={`Court - ${court.name}`}
      />
      <Divider orientation="left">Registrars</Divider>
      <form>
        {registrarsFormik.isSubmitting ? (
          <Spin />
        ) : (
          <div>
            <h4>Add registrar to court</h4>
            <Select
              defaultValue={registrarsFormik.values.email}
              style={{ width: 250 }}
              onChange={(value) =>
                registrarsFormik.setFieldValue("email", value)
              }
            >
              {allRegistrars
                .sort((a, b) => (a.email < b.email ? -1 : 1))
                .filter(
                  (registrar) =>
                    !courtRegistrars
                      .map((courtRegistrar) => courtRegistrar.email)
                      .includes(registrar.email)
                )
                .map((user) => (
                  <Select.Option value={user.email} key={user.email}>
                    {user.given_name} {user.family_name} ({user.email})
                  </Select.Option>
                ))}
            </Select>{" "}
            <Button
              type="primary"
              onClick={() => registrarsFormik.submitForm()}
              disabled={registrarsFormik.isSubmitting}
            >
              {registrarsFormik.isSubmitting ? "Adding..." : "Add"}
            </Button>
          </div>
        )}
      </form>
      <hr />
      <Table
        rowKey="email"
        dataSource={courtRegistrars}
        columns={[
          {
            title: "Given name",
            dataIndex: "given_name",
            key: "given_name",
          },
          {
            title: "Family name",
            dataIndex: "family_name",
            key: "family_name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
              <Space size="middle">
                <Popconfirm
                  title="Are you sure you want to remove this user?"
                  onConfirm={() => handleRemoveUser("REGISTRARS", record.email)}
                >
                  <Button>Remove</Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />

      <Divider orientation="left">Admins</Divider>
      <form>
        {adminsFormik.isSubmitting ? (
          <Spin />
        ) : (
          <div>
            <h4>Add admins to court</h4>
            <Select
              defaultValue={adminsFormik.values.email}
              style={{ width: 250 }}
              onChange={(value) => adminsFormik.setFieldValue("email", value)}
            >
              {allAdmins
                .sort((a, b) => (a.email < b.email ? -1 : 1))
                .filter(
                  (admin) =>
                    !courtAdmins
                      .map((courtAdmin) => courtAdmin.email)
                      .includes(admin.email)
                )
                .map((user) => (
                  <Select.Option value={user.email} key={user.email}>
                    {user.given_name} {user.family_name} ({user.email})
                  </Select.Option>
                ))}
            </Select>{" "}
            <Button
              type="primary"
              onClick={() => adminsFormik.submitForm()}
              disabled={adminsFormik.isSubmitting}
            >
              {adminsFormik.isSubmitting ? "Adding..." : "Add"}
            </Button>
          </div>
        )}
      </form>
      <hr />
      <Table
        rowKey="email"
        dataSource={courtAdmins}
        columns={[
          {
            title: "Given name",
            dataIndex: "given_name",
            key: "given_name",
          },
          {
            title: "Family name",
            dataIndex: "family_name",
            key: "family_name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
              <Space size="middle">
                <Popconfirm
                  title="Are you sure you want to remove this user?"
                  onConfirm={() => handleRemoveUser("ADMINS", record.email)}
                >
                  <Button>Remove</Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />
    </>
  );
};

export default Court;
