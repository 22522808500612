import { createGlobalStyle } from "styled-components/macro";

export default createGlobalStyle`
     body {
      font-family: 'Roboto', sans-serif;
    }

    svg {
        fill: currentColor;
    }

    * {
        box-sizing: border-box;
    }



    a {
      color: inherit;
    }
`;
