import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { RootState } from "../state/store";

const mapState = (state: RootState) => ({
  user: state.user,
});

type StateProps = ReturnType<typeof mapState>;
type Props = StateProps & RouteProps;

const ProtectedRoute: React.FunctionComponent<Props> = (props) => {
  if (props.user.data === undefined) {
    return null;
  }

  if (props.user.data === null) {
    return <Redirect to="/login" />;
  }

  if (
    !props.user.data.signInUserSession.idToken.payload["cognito:groups"] ||
    (!props.user.data.signInUserSession.idToken.payload["cognito:groups"].includes("Superadmins") &&
      !props.user.data.signInUserSession.idToken.payload["cognito:groups"].includes("Admins"))
  ) {
    return <Redirect to="/forbidden" />;
  }
  return <Route {...props} />;
};

export default connect(mapState)(ProtectedRoute);
