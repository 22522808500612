import { Menu, PageHeader } from "antd";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";

type Props = RouteComponentProps;

const Dashboard: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <PageHeader backIcon={false} title="Dashboard" />
      <Menu>
        <Menu.Item>
          <Link to="/court">Courts</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/users">Users</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/applications">Applications</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/applicants">Applicants</Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Dashboard;
