import { Button, Divider, Input, PageHeader, Popconfirm, Space, Spin, Table } from "antd";
import { LinkOutlined, UndoOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router";
import { courts, ApplicationType } from "csv-package";

import getApplication from "../helpers/getApplication";
import updateApplicationValidatedDate from "../helpers/updateApplicationValidatedDate";

const ApplicationsPage = () => {
  const { push } = useHistory();
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [applicationData, setApplicationData] = React.useState<ApplicationType | null>(null);

  const handleSearch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFetching(true);
    // do api call
    const data = await getApplication(searchTerm);
    setApplicationData(data);
    setFetching(false);
    if (data === null) {
      alert(`Application ${searchTerm} could not be found!`);
    }
  };

  return (
    <>
      <PageHeader onBack={() => push("/")} title="Applications" />

      {fetching ? (
        <Spin />
      ) : (
        <div>
          <label htmlFor="search_term">Application ID</label>
          <div style={{ height: "0.5em" }} />
          <Input
            id="search_term"
            name="search_term"
            placeholder="FVIO-0000"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <div style={{ height: "1em" }} />
          <Button type="primary" onClick={handleSearch}>
            Go
          </Button>
        </div>
      )}
      {applicationData !== null && (
        <>
          <Divider />
          <Space>
            <Button
              type="primary"
              href={`${process.env.REACT_APP_REGISTRAR_USER_POOL_CLIENT_CALLBACK_URL}application/${applicationData.ApplicationID}`}
              target="_blank"
              icon={<LinkOutlined />}
            >
              View in registrar portal
            </Button>
            {["verified", "archived"].includes(applicationData.Status) && (
              <Popconfirm
                title="Are you sure you want to reverify this application?"
                onConfirm={async () => {
                  const result = (await updateApplicationValidatedDate(
                    applicationData.ApplicationID
                  )) as ApplicationType;
                  setApplicationData((a) => ({ ...a, ...result }));
                  console.log({ result });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" icon={<UndoOutlined />}>
                  Reverify application
                </Button>
              </Popconfirm>
            )}
          </Space>
          <Divider />

          <Table
            columns={[
              { title: "Field", dataIndex: "field" },
              { title: "Value", dataIndex: "value" },
            ]}
            dataSource={[
              {
                field: "ID",
                value: applicationData.ApplicationID,
              },
              {
                field: "Status",
                value: applicationData.Status,
              },
              {
                field: "Submitted date",
                value: applicationData.Submitted && new Date(applicationData.Submitted).toLocaleString(),
              },
              {
                field: "Verified date",
                value: applicationData.Verified && new Date(applicationData.Verified).toLocaleString(),
              },
              {
                field: "User Given Name",
                value: applicationData.User?.given_name,
              },
              {
                field: "User Email",
                value: applicationData.User?.email,
              },
              {
                field: "Applicant Given Name",
                value: applicationData.Data.appl_det_giv_nam || applicationData.Data.ap_giv_nam,
              },
              {
                field: "Applicant Family Name",
                value: applicationData.Data.appl_det_fam_nam || applicationData.Data.ap_fam_nam,
              },
              {
                field: "Respondent Given Name",
                value: applicationData.Data.resp_giv_nam,
              },
              {
                field: "Respondent Family Name",
                value: applicationData.Data.resp_fam_nam,
              },
              {
                field: "Court",
                value:
                  applicationData.CourtID &&
                  courts.find((court) => court.court_id === applicationData.CourtID)?.name,
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default ApplicationsPage;
