import { Button } from "antd";
import { Auth } from "aws-amplify";
import React from "react";
import styled from "styled-components";

const Forbidden: React.FunctionComponent = () => (
  <Wrapper>
    <h1>Your user is not authorised to access the admin portal</h1>
    <Button type="primary" onClick={() => Auth.signOut()}>
      Logout
    </Button>
  </Wrapper>
);

export default Forbidden;

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
