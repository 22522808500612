import React from "react";
import { Auth } from "aws-amplify";
import { Layout, Menu } from "antd";

import { connect } from "react-redux";

import { RootState } from "../state/store";
import { Link } from "react-router-dom";

const mapState = (state: RootState) => ({
  user: state.user.data,
});

type StateProps = ReturnType<typeof mapState>;
type Props = StateProps;

const Header: React.FunctionComponent<Props> = (props) => {
  return (
    <Layout.Header>
      <Menu mode="horizontal">
        <Menu.Item>
          <Link to="/">Dashboard</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/courts">Courts</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/users">Users</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/applications">Applications</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/applicants">Applicants</Link>
        </Menu.Item>
        {props.user?.signInUserSession.idToken.payload[
          "cognito:groups"
        ].includes("Superadmins") && (
          <Menu.Item>
            <Link to="/courtlink">Courtlink</Link>
          </Menu.Item>
        )}
        {props.user !== null ? (
          <Menu.Item onClick={() => Auth.signOut()}>Logout</Menu.Item>
        ) : null}
      </Menu>
    </Layout.Header>
  );
};

export default connect(mapState)(Header);
