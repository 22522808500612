import { API, Auth } from "aws-amplify";

const getCourtlinkFile = async (key: string): Promise<string> => {
  const apiName = "csvApi";
  const path = `/private/admin/getCourtlinkFile`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      key,
    },
  };

  const result: string = await API.post(apiName, path, myInit);

  return result;
};

export default getCourtlinkFile;
