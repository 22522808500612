import { API, Auth } from "aws-amplify";
import { GroupType } from "../types";

type RemoveUserFromGroupParams = {
  email: string;
  group: GroupType;
};

const removeUserFromGroup = async (params: RemoveUserFromGroupParams): Promise<boolean> => {
  const apiName = "csvApi";
  const path = `/private/admin/removeUserFromGroup`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: params,
  };

  try {
    await API.post(apiName, path, myInit);
    return true;
  } catch (error) {
    alert("Remove user from court failed.");
    return false;
  }
};

export default removeUserFromGroup;
