const queryStringToJSON = (queryString: string) => {
  var pairs = queryString.slice(1).split("&");

  let result: { [key: string]: string } = {};
  pairs.forEach((pair) => {
    const part = pair.split("=");
    result[part[0]] = decodeURIComponent(part[1] || "");
  });

  return JSON.parse(JSON.stringify(result));
};

export default queryStringToJSON;
