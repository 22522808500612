import { API, Auth } from "aws-amplify";

const getCourtlinkFiles = async (): Promise<string[]> => {
  const apiName = "csvApi";
  const path = `/private/admin/getCourtlinkFiles`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  const result: string[] = await API.post(apiName, path, myInit);

  return result;
};

export default getCourtlinkFiles;
