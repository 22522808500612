import React from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { RootState, Dispatch } from "../state/store";
import styled from "styled-components";
import { Button } from "antd";

const mapState = (state: RootState) => ({
  user: state.user.data,
});

const mapDispatch = (dispatch: Dispatch) => ({
  setUser: dispatch.user.setAuthState,
});

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps & RouteComponentProps;

const Login: React.FunctionComponent<Props> = (props) => {
  if (props.user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <Button size="large" type="primary" onClick={() => Auth.federatedSignIn()}>
        Login
      </Button>
      <br />
      <p>
        You will be directed to the CSV authenitcation
        <br /> portal to authenticate and be redirected back
      </p>
    </Wrapper>
  );
};

export default connect(mapState, mapDispatch)(Login);

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
