import React from "react";
import Modal from "antd/lib/modal/Modal";

import { UserType } from "../types";
import { Input } from "antd";
import { API, Auth } from "aws-amplify";

type Props = {
  setSelectedUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  selectedUser: UserType;
};

const UpdateUserPasswordModal: React.FC<Props> = ({
  setSelectedUser,
  selectedUser,
}) => {
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleOk = async () => {
    setProcessing(true);
    const apiName = "csvApi";
    const path = `/private/admin/updateUserPassword`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      body: {
        password: newPassword,
        username: selectedUser.email,
      },
    };

    try {
      await API.post(apiName, path, myInit);
      setSelectedUser(null);
      setNewPassword("");
      setProcessing(false);
      setErrorMessage(null);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data.message);
      setProcessing(false);
    }
  };

  return (
    <Modal
      title={`Reset password for ${selectedUser.given_name} (${selectedUser.email})`}
      visible={selectedUser !== null}
      onCancel={() => setSelectedUser(null)}
      confirmLoading={processing}
      onOk={handleOk}
    >
      <label htmlFor="new_password">New password</label>
      <Input
        name="new_password"
        onChange={(e) => setNewPassword(e.target.value)}
        value={newPassword}
      />
      {errorMessage !== null && (
        <>
          <div>&nbsp;</div>
          <p style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</p>
        </>
      )}
    </Modal>
  );
};

export default UpdateUserPasswordModal;
