import { API, Auth } from "aws-amplify";
import { GroupType } from "../types";

interface AddUserToCourtParams {
  email: string;
  group: GroupType;
  courtID: string;
  given_name: string;
  family_name: string;
}

const addUserToCourt = async (
  values: AddUserToCourtParams
): Promise<boolean> => {
  const apiName = "csvApi";
  const path = `/private/admin/addUserToCourt`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      email: values.email,
      given_name: values.given_name,
      family_name: values.family_name,
      group: values.group,
      courtID: values.courtID,
    },
  };

  const result = await API.post(apiName, path, myInit);
  return Boolean(result);
};

export default addUserToCourt;
