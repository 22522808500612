import { API, Auth } from "aws-amplify";
import { ApplicationType } from "csv-package";

const getApplicantUser = async (applicantSearchString: string) => {
  const apiName = "csvApi";
  const path = `/private/admin/getApplicants`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: {
      term: applicantSearchString,
    },
  };
  return (await API.post(apiName, path, myInit)) as { email: string; given_name: string }[];
};

const getApplication = async (ApplicationID: string): Promise<ApplicationType | null> => {
  const apiName = "csvApi";
  const path = `/private/registrar/getApplication`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: {
      ApplicationID,
    },
  };

  try {
    const result = await API.post(apiName, path, myInit);
    if (result.User && result.User !== "") {
      // First Pass Using Applicant Given Name
      if (typeof result.Data.appl_det_giv_nam === "string") {
        const filteredUsers = await getApplicantUser(result.Data.appl_det_giv_nam);
        let applicationUser = filteredUsers.find((user) => user.email === result.User);
        if (!applicationUser) {
          // Second Pass Using All Applicants
          const allUsers = await getApplicantUser("");
          applicationUser = allUsers.find((user) => user.email === result.User);
        }
        if (applicationUser) {
          result.User = { emaiL: result.User, ...applicationUser };
        }
      }
    }

    return result as ApplicationType;
  } catch (error) {
    alert(error);
    return null;
  }
};

export default getApplication;
