import { API, Auth } from "aws-amplify";
import { ApplicationType } from "csv-package";

const updateApplicationValidatedDate = async (
  ApplicationID: string
): Promise<ApplicationType | null> => {
  const apiName = "csvApi";
  const path = `/private/registrar/updateApplicationStatus`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      ApplicationID,
      status: "verified",
    },
  };

  try {
    const result = await API.post(apiName, path, myInit);
    return result as ApplicationType;
  } catch (error) {
    alert(error);
    return null;
  }
};

export default updateApplicationValidatedDate;
