import { API, Auth } from "aws-amplify";
import { UserType } from "../types";

const getCourtUsers = async (courtID: string): Promise<UserType[]> => {
  const apiName = "csvApi";
  const path = `/private/admin/getCourtUsers`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: {
      courtID: courtID,
    },
  };

  const result: UserType[] = await API.post(apiName, path, myInit);
  return result;
};

export default getCourtUsers;
